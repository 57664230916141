import React from "react";
import Icon from "@ant-design/icons";

const mailSvg = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="20"
      height="18"
      rx="2"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path d="M1 4L11 12L21 3" stroke="currentColor" strokeWidth="2" />
  </svg>
);

const EMailIcon = (props: any) => (
  <Icon component={mailSvg} {...props} />
);

export default EMailIcon;
