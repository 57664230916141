import React from "react";
import Icon from "@ant-design/icons";

const CloseSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 44 44"
  >
    <g
      id="x_light_blue"
      data-name="x light blue"
      transform="translate(-310 -854)"
    >
      <rect
        id="Rectangle_521"
        data-name="Rectangle 521"
        width="44"
        height="44"
        transform="translate(310 854)"
        fill="#6d6e71"
        opacity="0"
      />
      <g
        id="Group_3307"
        data-name="Group 3307"
        transform="translate(-1000.91 553.091)"
      >
        <line
          id="Line_547"
          data-name="Line 547"
          x1="21.818"
          y2="21.818"
          transform="translate(1322.5 312.5)"
          fill="none"
          stroke="#2c6fd1"
          strokeWidth="2"
        />
        <line
          id="Line_548"
          data-name="Line 548"
          x2="21.818"
          y2="21.818"
          transform="translate(1322.5 312.5)"
          fill="none"
          stroke="#2c6fd1"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);

const CloseIcon = (props: any) => (
  <Icon component={CloseSVG} {...props} />
);

export default CloseIcon;
