import React from "react";
import Icon from "@ant-design/icons";

const deleteProfileSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="92.447"
    height="79.876"
    viewBox="0 0 92.447 79.876"
  >
    <defs>
      <clipPath id="clip-path">
        <circle
          id="Ellipse_255"
          data-name="Ellipse 255"
          cx="39.438"
          cy="39.438"
          r="39.438"
          fill="#ededed"
        />
      </clipPath>
    </defs>
    <g
      id="delete_account"
      data-name="delete account"
      transform="translate(-921 -411)"
    >
      <g id="Group_4977" data-name="Group 4977" transform="translate(921 412)">
        <circle
          id="Ellipse_251"
          data-name="Ellipse 251"
          cx="39.438"
          cy="39.438"
          r="39.438"
          fill="#d1d3d4"
        />
        <g
          id="Mask_Group_15"
          data-name="Mask Group 15"
          clipPath="url(#clip-path)"
        >
          <g
            id="Group_4389"
            data-name="Group 4389"
            transform="translate(19.72 16.432)"
          >
            <circle
              id="Ellipse_252"
              data-name="Ellipse 252"
              cx="13.146"
              cy="13.146"
              r="13.146"
              transform="translate(6.573)"
              fill="#fff"
            />
            <rect
              id="Rectangle_1045"
              data-name="Rectangle 1045"
              width="39.438"
              height="49.297"
              rx="19.719"
              transform="translate(0 23.005)"
              fill="#fff"
            />
          </g>
        </g>
        <g
          id="Ellipse_254"
          data-name="Ellipse 254"
          fill="none"
          stroke="#d1d3d4"
          strokeWidth="2"
        >
          <circle cx="39.438" cy="39.438" r="39.438" stroke="none" />
          <circle cx="39.438" cy="39.438" r="38.438" fill="none" />
        </g>
      </g>
      <g
        id="Group_3720"
        data-name="Group 3720"
        transform="translate(-27 -53.049)"
      >
        <circle
          id="Ellipse_20"
          data-name="Ellipse 20"
          cx="14.724"
          cy="14.724"
          r="14.724"
          fill="#f8f8f8"
          stroke="#fe000c"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_188"
          data-name="Line 188"
          y1="10.708"
          x2="10.708"
          transform="translate(1019.369 474.418)"
          fill="none"
          stroke="#fe000c"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_189"
          data-name="Line 189"
          x2="10.708"
          y2="10.708"
          transform="translate(1019.369 474.418)"
          fill="none"
          stroke="#fe000c"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);

const DeleteProfileIcon = (props: any) => (
  <Icon component={deleteProfileSVG} {...props} />
);

export default DeleteProfileIcon;
