import React from "react";
import Icon from "@ant-design/icons";

const FacebookSvg = () => (
  <svg  viewBox="0 0 32 32">
    <path fill="currenColor" d="M19 6h5v-6h-5c-3.86 0-7 3.14-7 7v3h-4v6h4v16h6v-16h5l1-6h-6v-3c0-0.542 0.458-1 1-1z"></path>
  </svg>
);

const FacebookIcon = (props: any) => (
  <Icon component={FacebookSvg} {...props} />
);

export default FacebookIcon;
