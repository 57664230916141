import React from "react";
import Icon from "@ant-design/icons";

const GarageDeleteSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="95.447"
    height="81.452"
    viewBox="0 0 95.447 81.452"
  >
    <g id="garage_x" data-name="garage x" transform="translate(2 0.998)">
      <g
        id="ico_tool"
        data-name="ico tool"
        transform="translate(-334.342 -117.543)"
      >
        <g id="garage" transform="translate(332.342 117.996)">
          <g
            id="Group_3418"
            data-name="Group 3418"
            transform="translate(0 0.002)"
          >
            <g id="Group_3417" data-name="Group 3417">
              <path
                id="Path_923"
                data-name="Path 923"
                d="M79.1,13.408,40.435.075a1.333,1.333,0,0,0-.869,0L.9,13.408a1.333,1.333,0,0,0-.9,1.26v64A1.333,1.333,0,0,0,1.333,80H8a1.333,1.333,0,0,0,1.333-1.333V37.335H70.667V78.668A1.333,1.333,0,0,0,72,80h6.667A1.333,1.333,0,0,0,80,78.668v-64A1.333,1.333,0,0,0,79.1,13.408ZM6.667,36V77.335h-4V26.668h4Zm64-1.333H9.333V32H70.667Zm0-5.333H9.333V26.668H70.667Zm6.667,48h-4V26.668h4Zm0-53.333H2.667V15.619L40,2.745,77.333,15.619Z"
                transform="translate(0 -0.002)"
                fill="#d1d3d4"
              />
            </g>
          </g>
          <g
            id="Group_3420"
            data-name="Group 3420"
            transform="translate(13.331 43.999)"
          >
            <g id="Group_3419" data-name="Group 3419">
              <path
                id="Path_924"
                data-name="Path 924"
                d="M132.6,277.466,127.666,275l-4.4-8.8a3.979,3.979,0,0,0-3.578-2.2H93.649a3.979,3.979,0,0,0-3.578,2.211l-4.4,8.8-4.934,2.467A1.333,1.333,0,0,0,80,278.667v18.668A2.667,2.667,0,0,0,82.667,300H88a2.667,2.667,0,0,0,2.667-2.667v-4h32v4A2.667,2.667,0,0,0,125.337,300h5.334a2.667,2.667,0,0,0,2.667-2.667V278.667A1.333,1.333,0,0,0,132.6,277.466ZM92.457,267.4a1.325,1.325,0,0,1,1.192-.737h26.039a1.325,1.325,0,0,1,1.192.737l3.632,7.263H88.825ZM88,297.335H82.667v-4H88Zm42.67,0h-5.334v-4h5.334Zm0-6.667h-48V279.491l4.315-2.158h39.374l4.315,2.158Z"
                transform="translate(-80 -263.999)"
                fill="#d1d3d4"
              />
            </g>
          </g>
          <g
            id="Group_3422"
            data-name="Group 3422"
            transform="translate(18.666 60.001)"
          >
            <g id="Group_3421" data-name="Group 3421">
              <path
                id="Path_925"
                data-name="Path 925"
                d="M118.667,360h-4A2.667,2.667,0,0,0,112,362.666v2.667A2.667,2.667,0,0,0,114.667,368h4a2.667,2.667,0,0,0,2.667-2.667v-2.667A2.667,2.667,0,0,0,118.667,360Zm0,5.334h-4v-2.667h4Z"
                transform="translate(-112 -359.999)"
                fill="#d1d3d4"
              />
            </g>
          </g>
          <g
            id="Group_3424"
            data-name="Group 3424"
            transform="translate(51.999 60.001)"
          >
            <g id="Group_3423" data-name="Group 3423">
              <path
                id="Path_926"
                data-name="Path 926"
                d="M318.667,360h-4A2.667,2.667,0,0,0,312,362.666v2.667A2.667,2.667,0,0,0,314.667,368h4a2.667,2.667,0,0,0,2.667-2.667v-2.667A2.667,2.667,0,0,0,318.667,360Zm0,5.334h-4v-2.667h4Z"
                transform="translate(-312 -359.999)"
                fill="#d1d3d4"
              />
            </g>
          </g>
          <g
            id="Group_3426"
            data-name="Group 3426"
            transform="translate(30.666 60.001)"
          >
            <g id="Group_3425" data-name="Group 3425">
              <path
                id="Path_927"
                data-name="Path 927"
                d="M200,360H186.667A2.667,2.667,0,0,0,184,362.666v2.667A2.667,2.667,0,0,0,186.667,368H200a2.667,2.667,0,0,0,2.667-2.667v-2.667A2.667,2.667,0,0,0,200,360Zm0,5.334H186.667v-2.667H200Z"
                transform="translate(-184 -359.999)"
                fill="#d1d3d4"
              />
            </g>
          </g>
        </g>
      </g>
      <g
        id="Group_3720"
        data-name="Group 3720"
        transform="translate(-947 -465.047)"
      >
        <circle
          id="Ellipse_20"
          data-name="Ellipse 20"
          cx="14.724"
          cy="14.724"
          r="14.724"
          transform="translate(1010 465.049)"
          fill="#f8f8f8"
          stroke="#fe000c"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_188"
          data-name="Line 188"
          y1="10.708"
          x2="10.708"
          transform="translate(1019.369 474.418)"
          fill="none"
          stroke="#fe000c"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_189"
          data-name="Line 189"
          x2="10.708"
          y2="10.708"
          transform="translate(1019.369 474.418)"
          fill="none"
          stroke="#fe000c"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);

const GarageDeleteIcon = (props: any) => (
  <Icon component={GarageDeleteSVG} {...props} />
);

export default GarageDeleteIcon;
