import React from "react";
import Icon from "@ant-design/icons";

const heartSvg = () => (
  <svg
    width="24"
    height="21"
    viewBox="0 0 24 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 6.4C1 2 4.5 1 6.5 1C9 1 11 3 12 4.5C13 3 15 1 17.5 1C19.5 1 23 2 23 6.4C23 13 12 19 12 19C12 19 1 13 1 6.4Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

const HeartIcon = (props: any) => (
  <Icon component={heartSvg} {...props} />
);

export default HeartIcon;
